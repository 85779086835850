<!--回弹检测-->
<template>
    <div class="springBackCheck" v-loading="loading">
        <div>
            <table class="base-table">
                <tr>
                    <th>任务编号</th>
                    <td>{{ task_number }}</td>
                    <th>工程名称</th>
                    <td style="padding-left: 0.15rem">
                        {{ form.springBack.project_name }}
                    </td>
                </tr>
                <tr>
                    <th>施工单位</th>
                    <td>{{ form.springBack.sgdw_name }}</td>
                    <th>监理单位</th>
                    <td>{{ form.springBack.supervise_unit }}</td>
                </tr>
                <tr>
                    <th>施工部位</th>
                    <td>{{ form.springBack.place_name }}</td>
                    <th>设计单位</th>
                    <td>{{ form.springBack.design_unit }}</td>
                </tr>
                <tr>
                    <th>施工方法</th>
                    <td>{{ form.springBack.construction_method }}</td>
                    <th>强度等级</th>
                    <td>{{ form.springBack.strength_grade_name }}</td>
                </tr>
                <tr>
                    <th>施工日期</th>
                    <td>
                        {{ form.springBack.construction_date }}
                    </td>
                    <th>回弹日期</th>
                    <td>
                        {{ form.springBack.springback_date }}
                    </td>
                </tr>
                <tr>
                    <th>回弹人1</th>
                    <td>{{ form.springBack.springbacker1 }}</td>
                    <th>回弹人2</th>
                    <td>{{ form.springBack.springbacker2 }}</td>
                </tr>
                <tr>
                    <th>陪同人</th>
                    <td colspan="3">
                        {{ form.springBack.attendanter }}
                    </td>
                </tr>
            </table>

            <table>
                <tr>
                    <th colspan="4">
                        <div class="cell-space">
                            <span>市场价格信息</span>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th>原材料分类</th>
                    <th>原材料子类</th>
                    <th>规格</th>
                    <th>实际用量</th>
                </tr>
                <tr
                    v-for="(item,index) in form.concreteSpringbackFormulaUsedqtyOutput"
                    :key="'jg'+index"
                >
                    <td>{{ item.yclflmc }}</td>
                    <td>{{ item.yclzlmc }}</td>
                    <td>{{ item.yclggmc }}</td>
                    <td>{{ item.formula }}</td>
                </tr>
                <tr v-if="(form.concreteSpringbackFormulaUsedqtyOutput||[]).length==0">
                    <td colspan="4" class="no-data">
                        暂无数据
                    </td>
                </tr>
            </table>

            <table>
                <tr>
                    <th colspan="3">
                        <div class="cell-space">
                            <span>出厂强度记录</span>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th>施工日期</th>
                    <th>7天强度</th>
                    <th>28天强度</th>
                </tr>
                <tr
                    v-for="(item,index) in form.concreteSpringbackFormulaStrengthOutput"
                    :key="'jg'+index"
                >
                    <td>
                        {{ item.construction_date }}
                    </td>
                    <td>{{ item.strength_7day }}</td>
                    <td>{{ item.strength_28day }}</td>
                </tr>
                <tr v-if="(form.concreteSpringbackFormulaStrengthOutput||[]).length==0">
                    <td colspan="3" class="no-data">
                        暂无数据
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'spring-back-check',
    data() {
        return {
            loading: false,
            menuVisible: false,

            // 任务单号
            task_number: '',

            form: {
                springBack: {},
                // 市场价格信息
                concreteSpringbackFormulaUsedqtyOutput: [],
                // 出厂强度记录
                concreteSpringbackFormulaStrengthOutput: [],
            },
        };
    },
    props: {
        extr: {
            type: [Object],
        },
    },
    created() {
        this.task_number = this.extr.taskNumber;
        this.getData();
    },
    methods: {
        // 获取基本数据
        getData() {
            this.loading = true;
            this.$axios.get('/interfaceApi/production/springback/get/' + this.task_number)
                .then(res => {
                    this.form = res;
                    this.formulaUsedqtyOutput.springback_number = res.springBack.springback_number;
                }).catch(e => {
                    this.$message.warning(e.ErrorCode.Message);
                }).finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
.springBackCheck
    width 100%;
    height 100%;
    padding 0 0.2rem
    overflow auto
    table
        border-spacing 0px
        border-collapse collapse
        width 100%
        table-layout fixed
        margin 0.1rem 0
        tr
            th,td
                font-size 0.16rem
                border 1px solid #D7D7D7
                text-align left
                padding 0.04rem 0.2rem
                .cell-space
                    width 100%;
                    display flex
                    justify-content space-between
            th
                padding 0.08rem 0.2rem
                color #022782
                background #F6F8FC
                .el-button
                    color #fff;
            .no-data
                text-align center
                padding 0.1rem
                color #b2b2b2

</style>
